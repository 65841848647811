<template>
    <v-dialog persistent max-width="640" v-model="dialog">
        <v-card>
            <v-card-title>Confirmation</v-card-title>
            <v-card-text class="pb-0">
                <span class="text-subtitle-1" v-if="!loading">Are you sure that you want to submit the feedback for this outlet visiting? Once you submitted, you are no longer to edit it again.</span>
                <span class="text-subtitle-1" v-if="loading">Submitting your feedback now. Please be patient</span>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="close" :disabled="loading">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" :loading="loading" @click="save">Submit</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'OutletVisitingFeedbackConfirmationDialog',
    data() {
        return {
            loading: false,
            dialog: false,
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.loading = false
        },
        save: function() {
            this.dialog = false
            this.$emit('confirmed')
        }
    }
}

</script>