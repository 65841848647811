<template>
    <div>
        <v-row v-if="!loading && !permissions.can_edit">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Outlet Visiting Schedule Info</p>
                <p class="text-title mb-0">You do not have permission to view this outlet visiting schedule info</p>
            </v-col>
        </v-row>
        <template v-if="!loading && permissions.can_edit">
            <p class="text-h5 pb-6 mb-0">Outlet Visiting Schedule Info</p>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                    <p class="text-h6 mb-0">Outlet Info</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="Project" :text="object.shift.project"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="Campaign" :text="object.shift.campaign"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="Outlet" :text="object.outlet.name"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="District" :text="object.outlet.district"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <display-label label="Address" :text="object.outlet.address"></display-label>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                    <p class="text-h6 mb-0">Shift Info</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="Working Date" :text="object.shift.work_date"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="Start Time" :text="object.shift.start_time"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="End Time" :text="object.shift.end_time"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="Allowed Break Hour" :text="object.shift.break_time"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="Total Hours" :text="object.shift.total_hours"></display-label>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                    <p class="text-h6 mb-0">Promoter Info</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="Promoter Name" :text="object.shift.promoter.name"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <display-label label="Mobile Number" :text="object.shift.promoter.mobile_number"></display-label>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                    <p class="text-h6 mb-0">Feedback Info</p>
                </v-col>
                <template v-if="object.has_feedback">
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <display-label label="Promoter Performance" :text="object.feedback.performance"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <display-label label="Crowd Feedback" :text="object.feedback.crowd_feedback"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <display-label label="Remarks" :text="object.feedback.remarks"></display-label>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <display-label label="Photo Attachments" :text="object.feedback.images.length===0?'No photo attachment':''"></display-label>
                        <div class="d-flex">
                            <v-img contain :key="index" max-width="100" class="my-3 mr-2 pointer" :src="item.thumbnail_url" v-for="(item, index) in object.feedback.images" @click="openImageDialog(item.image_url)"></v-img>
                        </div>
                    </v-col>
                </template>
                <v-layout align-center justify-center v-if="!object.has_feedback && permissions.can_edit">
                    <p class="my-4">There is no feedback for this outlet yet. <a @click="openCreateDialog">Submit</a> a feedback right now</p>
                </v-layout>
            </v-row>
        </template>
        <image-dialog title="Preview Photo Attachment" ref="imageDialog"></image-dialog>
        <create-dialog ref="createDialog" :crowd-feedbacks="crowd_feedbacks" :performances="performances" @created="updateObject"></create-dialog>
        <message-notifier ref="notifier"></message-notifier>
    </div>
</template>


<script>

import ImageDialog from '../../../../shared/ImageDialog.vue'
import DisplayLabel from '../../../../shared/DisplayLabel.vue'
import MessageNotifier from '../../../../shared/MessageNotifier.vue'
import OutletVisitingFeedbackCreateDialog from './OutletVisitingFeedbackCreateDialog.vue'

export default {
    name: 'DashboardOutletVisitingFeedbackForm',
    components: {
        messageNotifier: MessageNotifier,
        imageDialog: ImageDialog,
        displayLabel: DisplayLabel,
        createDialog: OutletVisitingFeedbackCreateDialog,
    },
    data() {
        return {
            loading: true,
            object: {
                is_closed: true,
                has_feedback: false,
                shift: {
                    promoter: {}
                },
                outlet: {},
                feedback: {
                    feedback: '',
                    images: []
                },
            },
            permissions: {
                can_edit: false
            },
            crowd_feedbacks: [],
            performances: []
        }
    },
    computed: {
        createDialog() {
            return this.$refs.createDialog
        },
        imageDialog() {
            return this.$refs.imageDialog
        },
        notifier() {
            return this.$refs.notifier
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.loading = true
            this.$store.dispatch('epanel/dashboard/getMyOutletVisitingInfo', this.$route.params).then((response) => {
                this.object = response.data.object
                this.crowd_feedbacks = response.data.crowd_feedbacks
                this.performances = response.data.performances
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        updateObject: function(item) {
            this.$set(this, 'object', item.object)
            this.createDialog.close()
            this.openNotifier(item.message)
        },
        openCreateDialog: function(){
            if(this.permissions.can_edit){
                this.createDialog.open()
            }
        },
        openImageDialog: function(imageUrl) {
            this.imageDialog.updateImageUrl(imageUrl)
            this.imageDialog.open()
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        }
    }
}

</script>