<template>
    <div>
        <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" ref="createDialog" v-model="dialog">
            <v-card>
                <v-toolbar dense color="primary" class="white--text">
                    <v-btn icon dark @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Submit Booth Location Feedback</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="openConfirmationDialog">
                            <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="py-6 px-4">
                    <v-form lazy-validation ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0" v-if="errors && errors.message">
                                <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <display-label label="Promoter Performance*"></display-label>
                                <v-radio-group row class="mt-3" :rules="rules.performance" :error-messages="errors.performance" v-model="object.performance">
                                    <v-radio :key="index" :ripple="false" :label="performance.text" :value="performance.value" v-for="(performance, index) in performances"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <display-label label="Crowd Feedback*"></display-label>
                                <v-radio-group row class="mt-3" :rules="rules.feedback" :error-messages="errors.feedback" v-model="object.feedback">
                                    <v-radio :key="index" :ripple="false" :label="crowdFeedback.text" :value="crowdFeedback.value" v-for="(crowdFeedback, index) in crowdFeedbacks"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <v-textarea dense clearable label="Remarks" :disabled="loading" :error-messages="errors.remarks" v-model="object.remarks"></v-textarea>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <p>Photo Attachment*</p>
                                <van-uploader multiple max-count="3" :after-read="afterReadHandler" v-model="object.photos"></van-uploader>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <confirmation-dialog ref="confirmationDialog" @confirmed="save"></confirmation-dialog>
	</div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import { stringifyJSON } from '@/utils/encodes'
import DisplayLabel from '../../../../shared/DisplayLabel.vue'
import OutletVisitingFeedbackConfirmationDialog from './OutletVisitingFeedbackConfirmationDialog.vue'
import { Toast } from 'vant'

export default {
    name: 'OutletVisitingFeedbackCreateDialog',
    components: {
        displayLabel: DisplayLabel,
        confirmationDialog: OutletVisitingFeedbackConfirmationDialog,
    },
    props: {
        crowdFeedbacks: {
            type: Array,
            required: false,
            default: () => []
        },
        performances: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            valid: true,
            dialog: false,
            loading: false,
            object: {
                photos: [],
                images: [],
                performance: '',
                feedback: '',
                remarks: ''
            },
            rules: {
                performance: [
                    (value) => !!value || 'Performance is required'
                ],
                feedback: [
                    (value) => !!value || 'Crowd feedback is required'
                ],
                photo: [
                    (value) => {
                        if(value.length < 1) {
                            return "Must attach at least 1 photo"
                        }

                        if(value.length > 5){
                            return "Only accepts up to 5 photos"
                        }

                        value.forEach((file) => {
                            var fileSize = Math.ceil(file.size / 1024)

                            if(fileSize > 2048) {
                                return 'Each photo musts less than 2MB'
                            }
                        })

                        return true
                    }
                ]
            },
            errors: []
        }
    },
    computed: {
        form() {
            return this.$refs.form
        },
        confirmationDialog(){
            return this.$refs.confirmationDialog
        },
        actionDialog() {
            return this.$refs.actionDialog
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        openConfirmationDialog: function() {
            if(this.form.validate()){
                this.confirmationDialog.open()
            }
        },
        close: function() {
            this.dialog = false
            this.loading = false
            this.object = { photos: [], images: [], performance: '', feedback: '', remarks: '' }
        },
        save: function(){
            this.loading = true
            var object = copyObject(this.object, ['photos', 'images'])
            object.images = stringifyJSON(this.object.images)

            Toast.loading({
                message: 'Submitting...',
                forbidClick: true,
                duration: 0
            })

            this.$store.dispatch('epanel/dashboard/createOutletVisitingFeedback', {id: this.$route.params.id, object: object}).then((response) => {
                this.$emit('created', {object: response.data.object, message: response.message})
                Toast.clear()
            }).catch((errors) => {
                Toast.loading({
                    message: 'Unable to submit photos',
                    forbidClick: true,
                    duration: 3000
                })
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
            })


        },
        afterReadHandler: function(file) {
            const maxSize = 600
            let canvas = document.createElement('canvas')
            let image = new Image()
            image.src = file.content
            image.onload = () => {
                var width = image.width, height = image.height
                if(width > height && width > maxSize){
                    height *= maxSize / width
                    width = maxSize
                }else if(width <= height && height > maxSize){
                    width *= maxSize / height;
                    height = maxSize
                }

                canvas.width = width
                canvas.height = height
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                this.object.images.push(canvas.toDataURL('image/jpeg'))
            }
        }
    }
}

</script>


<style scoped>
/deep/ .text-label {
    font-size: 1rem !important;
    font-weight: 500 !important;
}

</style>